import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  width: 98%;
  height: 380px;
  position: relative;
  margin-top: 48px;
  transform: translateX(20%);
  border: 1px solid #f2f2f2;
  outline: none;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    margin-top: 32px;
    height: 175px;
  }
`;

export const BookmarkButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  margin-bottom: 24px;

  svg {
    width: 32px;
    height: 32px;

    &.favorite {
      fill: var(--secondary-color);

      path {
        fill-rule: inherit;
      }
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 14px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

export const CourseDetails = styled.div`
  width: 49%;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(84, 0, 110, 0.85);
  padding: 48px 0px 0px 48px;

  h3 {
    max-width: 90%;
    font-size: var(--font-largest);
    color: var(--white-font-color);
    line-height: 36px;
    min-height: 200px;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  div {
    display: flex;
    align-items: center;

    span.divider {
      height: 22px;
      border: 1px solid var(--white-font-color);
      margin: 0 16px;
    }

    svg {
      margin-right: 8px;
      width: 24px;
      height: 24px;
    }
  }

  @media (max-width: 768px) {
    width: 72%;
    padding: 25px 0px 0px 18px;

    h3 {
      font-size: var(--font-small);
      line-height: 14px;
      margin-bottom: 11px;
      min-height: 78px;
    }

    div {
      align-items: stretch;

      span.divider {
        height: 12px;
        margin: 0 8px;
      }

      svg {
        margin-right: 6px;
        width: 12px;
        height: 12px;
      }
    }
  }
`;

export const CourseTime = styled.div`
  font-size: var(--font-large);
  color: var(--white-font-color);

  span {
    margin-top: 6px;
  }

  @media (max-width: 768px) {
    span {
      margin-top: 0;
    }

    font-size: var(--font-min);
  }
`;

export const CourseLikes = styled.div`
  font-size: var(--font-large);
  color: var(--white-font-color);

  span {
    margin-top: 6px;
  }

  @media (max-width: 768px) {
    span {
      margin-top: 0;
    }

    font-size: var(--font-min);
  }
`;
