import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Modal from 'react-modal';
import CheckIcon from '../../../../assets/svgs/wavy-check.svg';
import DepositionModal from './components/DepositionContent';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import { BsBookmark } from 'react-icons/bs';
import {
  AiOutlineClockCircle,
  AiOutlineLike,
  AiFillLike,
} from 'react-icons/ai';
import Annotations from './components/Annotations';
import ExtraMaterials from './components/ExtraMaterials';
import PlayerSambaVideos from '../../../../components/PlayerSambaVideos';
import {
  Container,
  CourseContentContainer,
  TabLinksContainer,
  DepositionsContent,
} from './style';
import { ExtraMaterialsResponse } from '../../../../services/course/models/extra-materials-interface';
import {
  addContentBookmark,
  removeContentBookmark,
} from '../../../../services/bookmark';
import { toast } from 'react-toastify';
import { likeCourse, dislikeCourse } from '../../../../services/like';
import Content from '../../../../models/content';
import { updateContentProgress as updateContentProgressService } from '../../../../services/content';
import { reviewContent } from '../../../../services/content-review';
import PlayScorm from '../PlayScorm';
import { convertToDuration } from '../../../../helpers/seconds-to-time';
import FileUpload from '../../../../components/FileUpload';
import { formatDistanceStrict } from 'date-fns';
import formatDataFromString from '../../../../helpers/convert-date';

interface CourseContentProps {
  content: Content;
  getEventListeners: (player: PlayerEventListener) => void;
  progress: number;
  finished: boolean;
  finishCourse: () => Promise<void>;
  startCourse: () => Promise<void>;
  examModal: boolean;
}

export interface PlayerEventListener {
  event: string;
  eventParam?: any;
  duration?: any;
}
const CourseContent: React.FC<CourseContentProps> = ({
  content,
  getEventListeners,
  progress,
  finished,
  finishCourse,
  startCourse,
  examModal,
}) => {
  const [showEvaluateBox, setShowEvaluateBox] = useState(!content.review);
  const [showDepositionModal, setShowDepositionModal] = useState(false);

  const [currentCourse, setCurrentCourse] = useState(content);
  const [addedToList, setAddedToList] = useState(content?.addedToList);
  const [addedToLike, setAddedToLike] = useState(content.userLiked);
  const [likes, setLikes] = useState(content?.numberOfLikes);

  const [lastWatchedTime, setLastWatchedTime] = useState<number>(0);
  const [tabToBeShown, setTabToBeShown] = useState('overview-tab');
  const [shouldShowPlayer, setShouldShowPlayer] = useState(false);

  useEffect(() => {
    setCurrentCourse(content);
    setAddedToList(content.addedToList || false);
    setAddedToLike(content.userLiked || false);
    setLikes(content.numberOfLikes);
  }, [content]);

  useEffect(() => {
    if (finished && !examModal) {
      setShowDepositionModal(true);
    } else {
      setShowDepositionModal(false);
    }
  }, [finished, examModal]);

  const displayTab = (tabToBeDisplayed: string) => {
    setTabToBeShown(tabToBeDisplayed);
  };

  const addToList = async () => {
    if (content && content.id) {
      try {
        const shouldAddToList = !content?.addedToList;

        if (shouldAddToList) {
          await addContentBookmark(content.id);
        } else {
          await removeContentBookmark(content.id);
        }

        content.addedToList = shouldAddToList;
        setAddedToList(content.addedToList);
      } catch (e) {
        toast.error('Erro ao adicionar curso aos favoritos.');
      }
    }
  };

  const addToLike = async (event: React.MouseEvent) => {
    if (content && content.id) {
      try {
        if (addedToLike) {
          await dislikeCourse(content.id);
          setLikes(likes - 1);
        } else {
          await likeCourse(content.id);
          setLikes(likes + 1);
        }
        setAddedToLike(!addedToLike);
      } catch (error) {
        toast.error('Erro ao curtir curso.');
      }
    }
  };

  const resumeTime = useMemo(() => {
    if (!currentCourse) {
      return false;
    }
    if (currentCourse.contentView) {
      setLastWatchedTime(currentCourse.contentView);
      return currentCourse.contentView;
    }

    return false;
  }, [currentCourse]);

  const daysToBeFinishContent = formatDistanceStrict(
    new Date(content.deadline),
    new Date(),
    {
      unit: 'day',
    },
  )
    .replace('days', 'dias')
    .replace('day', 'dia');

  const updateContentProgress = useCallback(
    async (time: number | null) => {
      await updateContentProgressService(content.id, time);

      return;
    },
    [content.id],
  );

  useEffect(() => {
    setShouldShowPlayer(false);

    process.nextTick(() => {
      const localContentUrl = content.contentUrl;
      const validUrlStart =
        'https://fast.player.liquidplatform.com/pApiv2/embed/';
      const isValidUrl =
        (localContentUrl && localContentUrl.startsWith(validUrlStart)) ||
        (localContentUrl && localContentUrl.startsWith(window.location.origin));

      if (isValidUrl) {
        setShouldShowPlayer(true);
      }
    });
  }, [content]);

  useEffect(() => {
    if (progress >= lastWatchedTime + 60) {
      setLastWatchedTime(progress);
      updateContentProgress(progress);
      return;
    }
    if (progress < lastWatchedTime) {
      if (progress > 1) {
        setLastWatchedTime(progress);
        updateContentProgress(progress);
        return;
      }
      setLastWatchedTime(0);
      updateContentProgress(null);
    }
  }, [progress, lastWatchedTime, updateContentProgress]);

  return (
    <Container>
      <CourseContentContainer>
        <Modal
          isOpen={showDepositionModal}
          overlayClassName="react-modal-overlay"
          className="react-modal-content"
        >
          <DepositionModal
            onClose={() => setShowDepositionModal(false)}
            content={content}
            concludedDeposition={() => setShowEvaluateBox(false)}
          />
        </Modal>
        <>
          <div className="upper-content">
            <div className="category">{currentCourse?.categoryName}</div>

            <h2 className="title">{currentCourse.title}</h2>
          </div>

          <div className="about-content">
            <div>
              <span className="duration">
                <AiOutlineClockCircle size={24} color="var(--primary-color)" />
                <span>
                  Duração: {`${convertToDuration(currentCourse.duration) || 0}`}
                </span>
              </span>

              <span className={`likes ${addedToLike && 'liked'}`}>
                <button onClick={e => addToLike(e)}>
                  {addedToLike ? (
                    <AiFillLike color="var(--primary-color)" size={24} />
                  ) : (
                    <AiOutlineLike color="#555555" size={24} />
                  )}
                </button>{' '}
                <span>
                  {likes} {likes === 1 ? 'curtida' : 'curtidas'}
                </span>
              </span>
            </div>
            {content.deadline ? (
              new Date(content.deadline) > new Date() ? (
                daysToBeFinishContent === '0 dias' ? (
                  <strong style={{ marginLeft: '10px' }}>
                    Hoje é o último dia para realizar o curso.
                  </strong>
                ) : (
                  <p style={{ marginLeft: '10px' }}>
                    Faltam{' '}
                    <strong style={{ color: 'var(--primary-color)' }}>
                      {daysToBeFinishContent}
                    </strong>{' '}
                    para o curso encerrar.
                  </p>
                )
              ) : (
                'A vigência do curso expirou.'
              )
            ) : (
              ''
            )}
            <button
              className={`
                  add-to-list
                  ${addedToList ? 'added-to-list' : ''}
                `}
              onClick={addToList}
            >
              <BsBookmark
                size={20}
                color={`${currentCourse.addedToList ? '#000' : '#555'}`}
              />{' '}
              {currentCourse.addedToList ? (
                <span className="remove-to-list">Remover dos favoritos</span>
              ) : (
                <span>Adicionar aos favoritos</span>
              )}
            </button>
          </div>
        </>

        <div className="lesson-content">
          <div className="lesson-video-container">
            <div className="player-container">
              {shouldShowPlayer ? (
                content.type.toUpperCase() === 'SCORM' ? (
                  <PlayScorm
                    finishCourseOrTrail={finishCourse}
                    startCourseOrTrail={startCourse}
                    selectedContent={content}
                  />
                ) : (
                  <PlayerSambaVideos
                    contentReference={content.contentUrl}
                    getEventListeners={getEventListeners}
                    resume={resumeTime ? resumeTime : 0}
                  />
                )
              ) : (
                <div className="player-icon-container">
                  <div className="player-icon">
                    <AiOutlinePlayCircle color="#FFF" size={200} />
                  </div>
                </div>
              )}
            </div>
          </div>

          {(finished || content.alreadyFinished) && showEvaluateBox && (
            <DepositionsContent>
              <div>
                <strong>Você concluiu:</strong>
                <br />
                <strong>{content.title}</strong>
                <br />
                <span>Seu depoimento é muito importante!</span>
              </div>

              <div className="buttons">
                <button
                  type="button"
                  onClick={() => setShowDepositionModal(true)}
                >
                  <img src={CheckIcon} alt="Check" />
                  Enviar depoimento
                </button>
                <button onClick={() => setShowEvaluateBox(false)}>
                  Não quero avaliar agora
                </button>
              </div>
            </DepositionsContent>
          )}
          {content.info.allow_user_file ? (
            <FileUpload reference_id={content.id} reference_type="content" />
          ) : (
            ''
          )}
          <div className="lesson-content-tabs">
            <TabLinksContainer>
              <button
                type="button"
                className={`
                overview-tab-link
                ${tabToBeShown === 'overview-tab' ? 'active' : ''}
              `}
                onClick={e => {
                  displayTab('overview-tab');
                }}
              >
                Visão Geral
              </button>
              <button
                type="button"
                className={`
                support-material-tab-link
                ${tabToBeShown === 'support-material-tab' ? 'active' : ''}
              `}
                onClick={e => {
                  displayTab('support-material-tab');
                }}
              >
                Material de Apoio
              </button>
              <button
                type="button"
                className={`
                    annotations-tab-link
                    ${tabToBeShown === 'annotations-tab' ? 'active' : ''}
                  `}
                onClick={e => {
                  displayTab('annotations-tab');
                }}
              >
                Anotações
              </button>
            </TabLinksContainer>

            <div className="content-tab-contents">
              {(() => {
                switch (tabToBeShown) {
                  case 'overview-tab':
                    return (
                      <div className="overview-tab-content">
                        {currentCourse.description || ''}
                      </div>
                    );

                  case 'support-material-tab':
                    return (
                      <div className="support-material-tab-content">
                        <ExtraMaterials
                          extra_material={content.extraMaterials}
                        />
                      </div>
                    );

                  case 'annotations-tab':
                    return (
                      currentCourse &&
                      currentCourse.id && (
                        <Annotations
                          contentId={currentCourse.id}
                          trailId={currentCourse.trailId}
                        />
                      )
                    );
                }
              })()}
            </div>
          </div>
        </div>
      </CourseContentContainer>
    </Container>
  );
};

export default CourseContent;
