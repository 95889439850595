import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Container, CourseModuleContainer, DepositionsContent } from './style';
import Trail from '../../../../models/trail';
import Course from '../../../../models/course';
import Content from '../../../../models/content';
import CheckIcon from '../../../../assets/svgs/wavy-check.svg';
import TrailModule from './components/TrailModule';
import ProgressBar from './components/ProgressBar';
import { toast } from 'react-toastify';
import { reviewTrailContent } from '../../../../services/trail-review';
import {
  getAttempts as getAttemptsService,
  IExam,
} from '../../../../services/exam';
import { isBefore, parseISO } from 'date-fns';
import FileUpload from '../../../../components/FileUpload';

interface TrailModuleProps {
  trail: Trail;
  modules: Course[];
  selectedContent: Content;
  contentList: Content[];
  exam: IExam;
  startTrail: () => void;
  showDepositionModal: () => void;
  setShowEvaluateBox: () => void;
  showEvaluateBox: boolean;
}

const TrailModules: React.FC<TrailModuleProps> = ({
  trail,
  modules,
  selectedContent,
  contentList,
  setShowEvaluateBox,
  showEvaluateBox,
  startTrail,
  showDepositionModal,
  exam,
}) => {
  const [ableToExam, setAbleToExam] = useState(false);
  const [nextAttemptsDate, setNextAttemptsDate] = useState<any>();
  const [userIsApproved, setUserIsApproved] = useState(false);

  function isEmpty(obj: Object): boolean {
    if (!obj) return true;
    return !Object.entries(obj).length;
  }

  const getAttempts = useCallback(async () => {
    if (trail.exam) {
      if ((exam.info && exam.info.enable_quiz) || trail.alreadyFinished) {
        getAttemptsService(trail.exam, trail.id).then(data => {
          const { alreadyStarted, alreadyFinished } = trail;
          const totalAttempts = data.user_total_attempts;
          if (data && data.date_allow_new_attempts) {
            setNextAttemptsDate(data.date_allow_new_attempts);
          }

          let isAble = false;

          const attempts = data.exam_user.length;
          const examUser = data.exam_user;

          let userIsApproved = false;
          if (exam.amount_questions && exam.amount_questions.length) {
            const numberOfQuestions = (exam.amount_questions || [])
              .map(amt => amt.amount * amt.points)
              .reduce((a, b) => a + b);
            userIsApproved = (
              (examUser as {
                initial_date: string;
                final_date: string;
                result: number;
                average: string | number;
                is_quiz: boolean;
              }[]) || []
            ).some(
              att =>
                !!att.initial_date &&
                !!trail.startDate &&
                new Date(att.initial_date).getTime() >
                  trail.startDate.getTime() &&
                !att.is_quiz &&
                !!att.final_date &&
                Math.round((numberOfQuestions * Number(exam.average)) / 100) <=
                  att.result,
            );
          }

          if (!alreadyStarted) {
            if (isEmpty(examUser)) isAble = true;
            else
              isAble =
                examUser[0].result === null && examUser[0].final_date === null;
          } else if (userIsApproved) {
            isAble = false;
            setUserIsApproved(true);
          } else if (alreadyFinished) {
            if (attempts === totalAttempts) isAble = false;
            else {
              if (isEmpty(exam)) return false;
              if (attempts === 1) isAble = true;
              else {
                const { amount, points } = exam.amount_questions[0];

                if (examUser && examUser.length) {
                  const bestScore = examUser
                    .map(({ result }: any) => result)
                    .reduce((a: any, b: any) => (a > b ? a : b));

                  const total = amount * points;

                  const minScore = total * (Number(exam.average) / 100);

                  isAble = bestScore < minScore || attempts < totalAttempts;
                }
              }
            }
            if (
              (examUser &&
                examUser[0] &&
                examUser[0].result === null &&
                examUser[0].final_date === null) ||
              attempts < totalAttempts
            ) {
              isAble = true;
            }
          }

          if (data && data.date_allow_new_attempts) {
            const canDoExam = isBefore(
              new Date(),
              parseISO(data.date_allow_new_attempts),
            );
            console.log(canDoExam);
            setAbleToExam(canDoExam ? false : isAble);
            return;
          }

          setAbleToExam(isAble);
        });
      }
    }
  }, [trail.id, trail.alreadyFinished, trail.alreadyStarted, exam]);

  useEffect(() => {
    getAttempts();
  }, [getAttempts]);

  const progress = useMemo(() => {
    if (trail && trail.courses && trail.courses.length && contentList) {
      const numberOfContents = contentList.length;
      const numberOfFinishedContents = contentList.filter(
        content => content.alreadyFinished,
      ).length;

      return (numberOfFinishedContents / numberOfContents) * 100;
    }

    return 0;
  }, [trail, contentList]);

  return (
    <Container>
      <CourseModuleContainer
        className={`${!trail.alreadyStarted && 'not-started'}`}
      >
        {trail.alreadyStarted && (
          <ProgressBar completionPercentage={progress} />
        )}
        {modules && modules.length ? (
          modules.map(module => (
            <TrailModule
              selectedContent={selectedContent}
              key={module.id}
              module={module}
              trailAlreadyStarted={trail.alreadyStarted}
              trailAlreadyFinished={trail.alreadyFinished}
              startTrail={module.isFirstCourse ? startTrail : () => {}}
              ableToExam={ableToExam}
              userIsApproved={userIsApproved}
              nextAttemptsDate={nextAttemptsDate}
              trail={trail}
            />
          ))
        ) : (
          <div></div>
        )}
      </CourseModuleContainer>
      {trail.alreadyFinished && showEvaluateBox && (
        <DepositionsContent>
          <strong>{`Você concluiu: ${trail.name}`}</strong>
          <span>Seu depoimento é muito importante!</span>

          <button type="button" onClick={showDepositionModal}>
            <img src={CheckIcon} alt="Check" />
            Enviar depoimento
          </button>
          <button onClick={() => setShowEvaluateBox()}>
            Não quero avaliar agora
          </button>
        </DepositionsContent>
      )}
      {trail.info.allow_user_file ? (
        <FileUpload reference_id={trail.id} reference_type="trail" />
      ) : (
        ''
      )}
    </Container>
  );
};

export default TrailModules;
