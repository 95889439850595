import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FiHome, FiPlayCircle, FiMap, FiRadio } from 'react-icons/fi';
import { BiConversation } from 'react-icons/bi';
import { MenuContainer } from './style';

const Menu: React.FC = () => {
  const location = useLocation();

  return (
    <MenuContainer>
      <Link
        to="/home"
        className={`
          ${!location.pathname || location.pathname === '/home' ? 'active' : ''}
        `}
      >
        <FiHome size={24} />
        Início
      </Link>
      <Link
        to="/courses"
        className={`
          ${location.pathname === '/courses' ? 'active' : ''}
        `}
      >
        <FiPlayCircle size={24} />
        Cursos
      </Link>
      <Link
        to="/trails"
        className={`
          ${location.pathname === '/trails' ? 'active' : ''}
        `}
      >
        <FiMap size={24} />
        Trilhas
      </Link>
      <Link
        to="/lives"
        className={`
          ${location.pathname === '/lives' ? 'active' : ''}
        `}
      >
        <FiRadio size={24} />
        Lives
      </Link>
      <Link
        to="/forum"
        className={`
          ${location.pathname.split('/')[1] === 'forum' ? 'active' : ''}
        `}
      >
        <BiConversation size={24} />
        Fórum
      </Link>
    </MenuContainer>
  );
};

export default Menu;
