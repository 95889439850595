import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  margin-top: 56px;
  
  > div {
    padding-bottom: 48px;
    margin-bottom: auto;
  }

  hr {
    border: 1px solid rgba(0, 0, 0, 0.15);
    width: 100%;
  }

  h2 {
    font-size: var(--font-largest);
    margin-bottom: 32px;
  }

  button.slick-arrow {
    width: 36px;
    height: 36px;
    top: -74px;

    &.slick-prev {
      right: 78px;
    }

    svg {
      width: 16px;
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 32px;
    margin-bottom: 24px;

    h2 {
      font-size: var(--font-default);
      margin-bottom: 16px;
    }
  }
`;

export const ListLive = styled.div`
  margin-bottom: 80px;
  margin-top: 80px;

  > h4 {
    margin-top: -24px;
  }

  > div {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-gap: 32px;

    >div {
      margin: 0;
      width: 100%;;
    }

    @media (max-width: 768px) {
      column-gap: 32px;
      justify-content: center;
    }
}
`
interface InLiveInterface {
  readonly heightPlayer: number;
};

export const InLive = styled.div<InLiveInterface>`
  margin-bottom: 80px;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;

    div {
      width: 80%;
      margin: 32px auto;
      
      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 22px;
        margin-bottom: 12px;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  iframe {
    margin: 0;
    width: 80%;
    margin: auto;
    height: ${({heightPlayer}) => heightPlayer+'px'};

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`
export const Now = styled.div`
  margin-bottom: 80px;
  width: 100%;

  > h4 {
    width: 100%;
    margin-top: -24px;
  }
`
export const Next = styled.div`
  margin-bottom: 80px;
  margin-top: 80px;
  width: 100%;

  > h4 {
    margin-top: -24px;
  }
`