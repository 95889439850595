import React, { useState } from 'react';
import { FiX } from 'react-icons/fi';
import { Rating } from 'react-simple-star-rating';
import { toast } from 'react-toastify';

import Banner from '../../assets/deposition-banner.png';
import checkEmptyString from '../../helpers/check-empty-string';
import Trail from '../../models/trail';

import { reviewTrailContent } from '../../services/trail-review';
import Textarea from '../Inputs/Textarea';

import { Container, Content } from './style';

interface ICreateDepositionModal {
  onClose: () => void;
  trail: Trail;
  setShowEvaluateBox?: () => void;
}

const CreateDepositionModal: React.FC<ICreateDepositionModal> = ({
  onClose,
  trail,
  setShowEvaluateBox,
}) => {
  const [description, setDescription] = useState('');
  const [rating, setRating] = useState(0);

  const handleRating = (rate: number) => {
    setRating(rate);
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const stars = rating / 20;
      await reviewTrailContent(trail.id, description, stars);
      toast.success('Depoimento cadastrado com sucesso!');
      onClose();

      if (setShowEvaluateBox) {
        setShowEvaluateBox();
      }
    } catch (err) {
      toast.error('Houve um erro ao avaliar a trilha!');
    }
  };

  return (
    <Container>
      <header>
        <img src={Banner} alt="Banner" />

        <div>
          <span>Você concluiu:</span>
          <strong>{trail.name}</strong>
        </div>

        <button onClick={onClose}>
          <FiX size={32} color="#FFF" />
        </button>
      </header>

      <Content>
        <div className="title">
          <span>Avalie e deixe seu depoimento!</span>
          <br />
          <Rating
            onClick={handleRating}
            ratingValue={rating}
            fillColorArray={[
              '#f17a45',
              '#f19745',
              '#f1a545',
              '#f1b345',
              '#f1d045',
            ]}
          />
        </div>

        <form onSubmit={onSubmit}>
          <label>Digite seu depoimento</label>
          <textarea
            onChange={e => setDescription(e.target.value)}
            autoFocus
            maxLength={1000}
          />
          <span className="characterCounter">{description.length}/1000</span>
          <div className="buttons">
            <button onClick={onClose} type="submit">
              Fazer depois
            </button>
            <button>Enviar depoimento</button>
          </div>
        </form>
      </Content>
    </Container>
  );
};

export default CreateDepositionModal;
