import React, { useState, useEffect } from 'react';

import ProfileMenu from '../../components/ProfileMenu';
import MobileMenuDropdown from '../../components/MobileMenuDropdown';

import { Container, CertificatesList } from './style';
import CertificateCard from '../../../../components/CertificateCard';
import CertificatesShimmer from './components/Skeleton';
import { getAllCertificates } from '../../../../services/certificate';

const Certificates: React.FC = () => {
  const [certificates, setCertificates] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);

  const getCertificates = async () => {
    setIsLoading(true);

    const localCertificates = await getAllCertificates();

    setCertificates(localCertificates);
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getCertificates();
    })();
  }, []);

  return (
    <Container className="content">
      <ProfileMenu />

      <div>
        <MobileMenuDropdown className="dropdown" />
        <h2>Certificados</h2>
        {isLoading ? (
          <CertificatesShimmer />
        ) : (
          <CertificatesList>
            {certificates && certificates.length ? (
              certificates.map(course => (
                <CertificateCard key={course.id} course={course} />
              ))
            ) : (
              <h4 style={{ padding: '15px' }}>
                Não foram encontrados cursos concluídos.
              </h4>
            )}
          </CertificatesList>
        )}
      </div>
    </Container>
  );
};

export default Certificates;
