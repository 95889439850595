import React from 'react';
import { ProgressBarContainer } from './style';

interface ProgressBarProps {
  completionPercentage: number;
  darkenProgress?: boolean;
  width?: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  completionPercentage,
  darkenProgress,
}) => {
  return (
    <ProgressBarContainer>
      <div className="course-progress-bar">
        <div
          className="progress"
          style={{
            width: `${Math.ceil(completionPercentage)}%`,
          }}
        ></div>
      </div>
    </ProgressBarContainer>
  );
};

export default ProgressBar;
