import React, { useCallback, useEffect, useState } from 'react';

import { Container, ListLive, InLive, Now, Next } from './style';

import Slider from 'react-slick';

import SliderNextButton from '../../components/SliderButtons/SliderNextButton';
import SliderPrevButton from '../../components/SliderButtons/SliderPrevButton';
import LiveCardItem from '../../components/LiveCardItem';
import SliderSkeleton from './Skeleton/SliderSkeleton';

import { coursesSliderSettings } from '../../config/sliderSettings';
import { toast } from 'react-toastify';
import {
  getCurrentLives as getCurrentLivesService,
  getNextLives as getNextLivesService,
  getPreviousLives as getPreviousLivesService,
} from '../../services/lives';
import Live from '../../models/lives';
import useWindowSize from '../../hooks/useWindowSize';
import ListSkeleton from './Skeleton/ListSkeleton';
import { useParams } from 'react-router-dom';
import PreFooter from '../../components/PreFooter';

const Lives: React.FC = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [isInLive, setIsInLive] = useState(true);

  const [currentLives, setCurrentLives] = useState<Live[]>([]);
  const [nextLives, setNextLives] = useState<Live[]>([]);
  const [liveNow, setLiveNow] = useState<Live>({} as Live);

  const [previousLives, setPreviousLives] = useState<Live[]>([]);

  const [heightPlayer, setHeightPlayer] = useState(0);

  const [width] = useWindowSize();

  const { id } = useParams<{ id: string | undefined }>()

  // Rules to put iframe in 16/9 aspect
  useEffect(() => {
    if (width > 1300) {
      setHeightPlayer(1016 * 9 / 16);
    } else if (width > 768) {
      setHeightPlayer(((80 / 100 * width) - 70) * 9 / 16);
    } else {
      setHeightPlayer((width - 70) * 9 / 16);
    }
  }, [
    width
  ])

  const getLives = useCallback(async () => {
    try {
      setIsLoading(true);
      const lives = await getCurrentLivesService();
      setCurrentLives(lives);

      const nextLives = await getNextLivesService();
      setNextLives(nextLives);

      const previousLivesData = await getPreviousLivesService();
      setPreviousLives(previousLivesData);

    } catch (error) {
      toast.error("Ocorreu um erro ao obter os dados. Recarregue a página.");
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getLives();
  }, [getLives]);

  useEffect(() => {
    if (currentLives.length || previousLives) {

      const selectedLiveCurrent = currentLives.filter(item => item.live_id === id);

      const selectedLivePrevious = previousLives.filter(item => item.live_id === id);

      if (selectedLivePrevious.length) {
        setLiveNow(selectedLivePrevious[0]);
        setIsInLive(false);
        return;
      }

      setIsInLive(true);

      if (selectedLiveCurrent.length) {
        setLiveNow(selectedLiveCurrent[0]);
        return;
      }

      if (currentLives.length && !id) {
        setLiveNow(currentLives[0]);
      }
    }
  }, [currentLives, id, previousLives]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [id])

  const getLiveUrl = (live: Live) => {
    const playerHash = process.env.REACT_APP_PLAYER_HASH;
    const playerBaseUrl = 'https://fast.player.liquidplatform.com/pApiv2/embed';
    if (live.channel_sv_id) {
      return `${playerBaseUrl}/${playerHash}/live/${live.channel_sv_id}`;
    }
    return `${playerBaseUrl}/${playerHash}`;
  };

  return (
    <Container>
      <div className="content">
        {liveNow.channel_sv_id && (
          <InLive heightPlayer={heightPlayer} >
            <h2>{isInLive ? 'Ao Vivo' : 'Live Gravada'}</h2>
            <div>
              <iframe frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Live Ao Vivo" src={getLiveUrl(liveNow)}></iframe>

              <div>
                <h3>{liveNow.name}</h3>
                <p>{liveNow.description}</p>
              </div>

            </div>
          </InLive>
        )}

        {(currentLives.length > 1 || isLoading || (!isInLive && !!currentLives.length)) && (
          <Now>
            <h2>Acontecendo agora</h2>
            <Slider
              {...coursesSliderSettings}
              prevArrow={<SliderPrevButton />}
              nextArrow={<SliderNextButton />}
            >
              {isLoading ? (
                <SliderSkeleton />
              ) : currentLives && currentLives.length && (
                currentLives.map(live => (
                  <LiveCardItem hasAction={true} key={live.live_id} live={live} />
                ))
              )}
            </Slider>
          </Now>
        )}

        {(currentLives.length > 1 || (!isInLive && !!currentLives.length)) && (
          <hr />
        )}

        <Next>
          <h2>Próximas lives</h2>
          <Slider
            {...coursesSliderSettings}
            prevArrow={<SliderPrevButton />}
            nextArrow={<SliderNextButton />}
          >
            {isLoading ? (
              <SliderSkeleton />
            ) : nextLives && nextLives.length && (
              nextLives.map(live => (
                <LiveCardItem key={live.live_id} live={live} />
              ))
            )}
          </Slider>

          {!isLoading && !nextLives.length && (<h4>Não foram encontradas lives programadas.</h4>)}
        </Next>

        <hr />

        <ListLive>
          <h2>Lives anteriores</h2>
          {isLoading ? (<ListSkeleton />) : (
            <>
              {!!(previousLives && previousLives.length) ? (
                <div>
                  {previousLives.map(live => (
                    <>
                      <LiveCardItem hasAction key={live.live_id} live={live} />
                    </>
                  ))}
                </div>) : (<h4>Não foram encontradas lives finalizadas.</h4>)}
            </>
          )}
        </ListLive>
      </div>
      <PreFooter />
    </Container >
  );
}

export default Lives;